/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import get from "lodash/get"
import { graphql } from "gatsby"
import { paramsCheck } from "../functions/functions"
import Page from "../components/page"
import Hero from "../components/hero"
import { findBySlug, getBackgroundList, renderLineBreak } from "../helpers"
import Container from "../components/container"
import Column from "../components/column"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Text from "../components/text"
import cloneDeep from "lodash/cloneDeep"
import CTAsModule from "../components/ctas-module"
import RollOverCard from "../components/rollover-card"
import theme from "../gatsby-plugin-theme-ui/index"
import BenefitsModule from "../components/benefits-module"
import VideoTour from "../components/video-tour"

const TwoColumnsModule = ({
  bladePatternData,
  columnSizes,
  titleStyle,
  background,
}) => {
  if (!bladePatternData.contentCards || !bladePatternData.contentCards.length)
    return null

  const rightColumn = bladePatternData.contentCards[0]

  let initialContent = cloneDeep(rightColumn.description)
  let readMoreContent = cloneDeep(rightColumn.description)

  return (
    <div
      sx={
        background === "primary"
          ? {
              py: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
            }
          : {
              py: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
              backgroundColor: theme => theme.colors.backgrounds.primary,
            }
      }
    >
      <Container
        customStyle={{
          // px: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
        }}
      >
        <Row>
          <Column size={[12, 12, 4]}>
            {bladePatternData.title && (
              <Text
                type="h2"
                customStyle={{
                  mb: theme => theme.spacing.vertical.sm,
                  // mx: theme => [
                  //   theme.spacing.vertical.sm,
                  //   theme.spacing.vertical.md,
                  // ],
                  ...titleStyle,
                }}
              >
                {renderLineBreak(bladePatternData.title)}
              </Text>
            )}
          </Column>
          <Column size={[12, 12, 8]}>
            <Row>
              {initialContent ? <RichText textStyle={{fontSize:17}} data={initialContent} /> : null}

              {readMoreContent?.json?.content ? (
                <div>
                  {readMoreContent.json.content.map(el => (
                    <RichText textStyle={{fontSize:17}} data={get(el, "content[0].value", "")} />
                  ))}
                </div>
              ) : null}
            </Row>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

const Capabilities = ({ data }) => {
  paramsCheck()

  const renderHeroBladePattern = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "capabilities-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (!bladePatternData) return

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: bladePatternData.mobileImage,
            desktopImage: bladePatternData.desktopImage,
          },
        }}
        // type="secondary"
        verticalAlign="top"
        title={bladePatternData.title}
        titleStyle={{mt: theme => [
          null,
          null,
          theme.spacing.vertical.lg,
          theme.spacing.vertical.lg,
        ],}}
      />
    )
  }

  const renderIntroduction = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "capabilities-introducing",
    })

    if (!bladePatternData) return

    return (
      <TwoColumnsModule
        bladePatternData={bladePatternData}
        titleStyle={{ fontSize: [30, 30, 35] }}
        columnSizes={{ left: [12, 12, 4], right: [12, 12, 8] }}
        background={"primary"}
      />
    )
  }

  const [showModal, setShowModal] = React.useState(false)
  const [gatewayLink, setGatewayLink] = React.useState({
    to: "/",
    type: "internal",
  })

  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "capabilities-cta-module",
    })
    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
        ctaOnClick={(e, index, href) => {
          e.preventDefault()
          if (index === 0) {
            window.location.href = "/contact"
          }
          if (index === 1) {
            setShowModal(true)
            if (href) setGatewayLink(href)
          }
        }}
      />
    )
  }
  const downloadButton = (bladePatternData)=>{
    return <a
    sx={{
      ...theme.forms.buttonChevron,
      fontSize: 14,
      pr: 7,
      py: "5px",
      pl: 19,
      mt: 26,
      display: "flex",
      justifyContent: "space-between",
      width: 190,
      color: "primary",
      border: theme => `2px solid ${theme.colors.primary}`,
      background: "transparent",
      whiteSpace: "nowrap",
      textDecoration: "none",
      ":hover": {
        border: theme => `2px solid ${theme.colors.secondary}`,
        color: "secondary",
      },
    }}
    target="_blank"
    href={bladePatternData.ctaDestination} download
  >
    {bladePatternData.ctaTitle}
  </a>
  }
  const renderBuildingBlock = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "capabilities-better-building-blocks",
    })

    const titleStyle = `{{ fontSize: [30, 30, 40] }}`
    const columnSizes = `{{ left: [12, 12, 4], right: [12, 12, 8] }}`
    if (!bladePatternData) return
    console.log("bladePatternData :: ", bladePatternData)
    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <Container
          customStyle={
            {
              // px: theme => [theme.spacing.vertical.md],
              // px: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
            }
          }
        >
          <Row>
            <Column
              size={get(columnSizes, "left", [12, 12, 4])}
              customStyle={
                {
                  // px: theme => [
                  //   theme.spacing.vertical.sm,
                  //   theme.spacing.vertical.md,
                  // ],
                }
              }
            >
              {bladePatternData.title && (
                <Text
                  type="h2"
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    ...titleStyle,
                  }}
                >
                  {renderLineBreak(bladePatternData.title)}
                </Text>
              )}
              {bladePatternData.description ? (
                <Text customStyle={{ fontSize: 17 }}>
                  {bladePatternData.description.description}
                </Text>
              ) : null}
              {bladePatternData.ctaDestination &&
                bladePatternData.ctaTitle &&
                downloadButton(bladePatternData)}
            </Column>
            {bladePatternData?.contentCards.map(card => (
              <Column size={get(columnSizes, "right", [12, 12, 4])}>
                <RollOverCard
                  background={getBackgroundList(card)}
                  title={card.title}
                  description={card.description}
                  className="capabilities-list"
                />
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  const renderFastModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "capabilities-fast-module",
    })
    if (!benefitsModuleBladePatternData) return
    const titleStyle = `{{ fontSize: [30, 30, 40] }}`;
    const columnSizes = `{{ left: [12, 12, 4], right: [12, 12, 8] }}`;
    const vedioThumbnail = benefitsModuleBladePatternData.desktopImage
    ? benefitsModuleBladePatternData.desktopImage
    : benefitsModuleBladePatternData.mobileImage
    return (
      <div
        sx={{
          pb: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
          // pt: theme => [
          //   theme.spacing.vertical.sm,
          //   theme.spacing.vertical.md,
          //   theme.spacing.vertical.lg,
          // ],
        }}
      >
        <Container>
          <Row
            // customStyle={{
            //   gap: [
            //     null,
            //     null,
            //     theme => theme.spacing.vertical.sm,
            //     theme => theme.spacing.vertical.sm,
            //   ],
            // }}
          >
            <Column size={ [12, 12, 6]}>
               <VideoTour
                  video={{
                    title: "",
                    externalUrl: { externalUrl: benefitsModuleBladePatternData.anchorLink },
                    thumbnailImage:{...vedioThumbnail}
                  }}
                />
            </Column>

            <Column size={ [12, 12, 6]} sx={{ pl: theme =>  theme.spacing.vertical.md}}>
              {benefitsModuleBladePatternData.title && (
                <Text
                  type="h2"
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    ...titleStyle,
                  }}
                >
                  {renderLineBreak(benefitsModuleBladePatternData.title)}
                </Text>
              )}
              
              {benefitsModuleBladePatternData?.contentCards.map((card,i) => (
                  
                  <RichText data={card.description} textStyle={{fontSize:17}} key={i+card.description}/>
              ))}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  const renderEfficiencyModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "capabilities-efficiency-module",
    })
    const titleStyle = `{{ fontSize: [30, 30, 40] }}`
    const columnSizes = `{{ left: [12, 12, 4], right: [12, 12, 8] }}`
    if (!bladePatternData) return
    return (
      <div
        sx={{
          py: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
          pt: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        <Container
          customStyle={
            {
              // px: theme => [theme.spacing.vertical.md],
            }
          }
        >
          <Row
            customStyle={{
              gap: [
                null,
                null,
                theme => theme.spacing.vertical.sm,
                theme => theme.spacing.vertical.sm,
              ],
            }}
          >
            <Column size={get(columnSizes, "left", [12, 12, 6])}>
              <img
                src={bladePatternData.desktopImage.file.url}
                alt=""
                sx={{
                  width: "100%",
                  mb: [3, 3, 0, 0],
                  borderRadius: "5px",
                }}
              />
              <Text
                type="h3"
                customStyle={{
                  // mb: theme => theme.spacing.vertical.sm,
                  color: "#31aabb",
                  // margin: "50px 50px 0px 0px",
                  mt: [0, 0, 6, 6],
                  mr: [0, 0, 2, 2],
                }}
              >
                {bladePatternData.ctaTitle}
              </Text>

              <Text
                type="h5"
                customStyle={{
                  mb: [
                    6,
                    6,
                    theme => theme.spacing.vertical.sm,
                    theme => theme.spacing.vertical.sm,
                  ],
                  mt: [3, 3, 3, 3],
                  color: "#31aabb",
                }}
              >
                {bladePatternData.ctaDestination}
              </Text>
            </Column>

            <Column size={get(columnSizes, "right", [12, 12, 5])}>
              {bladePatternData.title && (
                <Text
                  type="h2"
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    ...titleStyle,
                  }}
                >
                  {renderLineBreak(bladePatternData.title)}
                </Text>
              )}
              {bladePatternData.description ? (
                <Text customStyle={{fontSize:17}}>{bladePatternData.description.description}</Text>
              ) : null}
              <br />
              {bladePatternData?.contentCards.map(card => (
                <>
                  <Text
                    type="h3"
                    customStyle={{
                      mb: theme => theme.spacing.vertical.sm,
                      margin: "0% !important",
                      ...titleStyle,
                    }}
                  >
                    {card.title}
                  </Text>
                  <RichText data={card.description} textStyle={{fontSize:17}} />
                </>
              ))}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Page data={data.contentfulPage}>
        {renderHeroBladePattern()}
        {renderIntroduction()}
        {renderFastModule()}
        {renderBuildingBlock()}
        {renderEfficiencyModule()}
        {renderCtaModule()}
      </Page>
    </React.Fragment>
  )
}
export default Capabilities

export const query = graphql`
  query capabilitiesQuery {
    contentfulPage(slug: { eq: "capabilities" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
